import * as React from "react"
import { useForm } from "react-hook-form"
import { browserCompat } from "../utils"
import { navigate } from "gatsby"
import { PasswordGuardContext } from "../components/password-guard.context"

export const USER_VALIDATED = "USER_VALIDATED"

export default function Login() {
  const { isVerified, setIsVeryfied } = React.useContext(PasswordGuardContext)
  const { register, handleSubmit } = useForm()

  const verify = React.useCallback(
    ({ password }) => {
      if (password === process.env.GATSBY_LANDING_PASS) {
        setIsVeryfied(true)
        browserCompat(() => sessionStorage.setItem(USER_VALIDATED, true))
      }
    },
    [setIsVeryfied]
  )

  if (isVerified) navigate("/")
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form onSubmit={handleSubmit(verify)}>
        <label id="passwd" htmlFor="password">
          Password:
          <input name="password" type="password" ref={register}></input>
          <input type="submit" />
        </label>
      </form>
      <form
        data-netlify="true"
        netlify-honeypot="bot-field"
        name="contact"
        method="POST"
        action="/thanks"
        hidden
      >
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control input subscribe-form-field"
            id="email"
            name="email"
            placeholder="Enter your e-mail address."
            autoComplete="email"
          />
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <button
            type="submit"
            className="button btn solid-btn subscribe-form-button"
            id="submit"
          >
            <span className="subscribe-icon-1"></span>
          </button>
        </div>
      </form>
    </div>
  )
}
